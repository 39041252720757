/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Row, Col } from "reactstrap";
import { sendOtp, loginWithOtp, toggleLoading } from "store/actions";

import LoginWithOtp from "./LoginWithOtp";
import SendOtp from "./SendOtp";

const Login = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");

    useEffect(() => {
        dispatch(toggleLoading(false));
    }, []);

    const handleSendOtp = async (value) => {
        let result = await dispatch(sendOtp(value));
        if (result) {
            toast.success("OTP sent successfully");
            setEmail(value.email);
        }
    };

    const handleOtpLogin = (value) => {
        dispatch(loginWithOtp({ email: email, otp: value.otp }));
    };

    return (
        <div style={{ paddingTop: "20em", paddingBottom: "20em" }}>
            <Row className="justify-content-center">
                <Col lg={6}>
                    {email === "" ? <SendOtp onSubmit={handleSendOtp} /> : <LoginWithOtp onSubmit={handleOtpLogin} />}
                </Col>
            </Row>
        </div>
    );
};

export default Login;

/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// react plugin used to create charts
// import { Pie } from "react-chartjs-2";
// reactstrap components
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import { listHomepageData, resetHomepageData } from "store/actions/userActions";
// core components
// import { dashboard24HoursPerformanceChart, dashboardEmailStatisticsChart, dashboardNASDAQChart } from "variables/charts.js";

function Dashboard() {
    const dispatch = useDispatch();

    const { data } = useSelector(({ users: { homepageData } }) => ({
        data: homepageData,
    }));

    useEffect(() => {
        dispatch(resetHomepageData());
        dispatch(listHomepageData());
    }, []);

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="fas fa-images" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Own NFTs</p>
                                            <CardTitle tag="p">{data?.total_own_nfts}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats"></div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="fas fa-images" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Sold NFTs</p>
                                            <CardTitle tag="p">{data?.total_sold_nfts ?? 0}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats"></div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md="4" lg="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">Email Statistics</CardTitle>
                                <p className="card-category">Last Campaign Performance</p>
                            </CardHeader>
                            <CardBody style={{ height: "266px" }}>
                                <Pie
                                    data={dashboardEmailStatisticsChart.data}
                                    options={dashboardEmailStatisticsChart.options}
                                />
                            </CardBody>
                            <CardFooter>
                                <div className="legend">
                                    <i className="fa fa-circle text-primary" /> Opened{" "}
                                    <i className="fa fa-circle text-warning" /> Read{" "}
                                    <i className="fa fa-circle text-danger" /> Deleted{" "}
                                    <i className="fa fa-circle text-gray" /> Unopened
                                </div>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar" /> Number of emails sent
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row> */}
            </div>
        </>
    );
}

export default Dashboard;

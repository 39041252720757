import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Card, CardBody, CardHeader, Input, Button } from "reactstrap";

const SendOtp = ({ onSubmit }) => {
    return (
        <Card>
            <CardHeader>Enter Email To Send OTP</CardHeader>
            <CardBody>
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                    })}
                    onSubmit={onSubmit}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Input
                                autoComplete="on"
                                type="email"
                                placeholder="email"
                                name="email"
                                defaultValue={values.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required={true}
                            />
                            {Boolean(touched.email && errors.email) ? (
                                <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                    {errors.email}
                                </div>
                            ) : (
                                ""
                            )}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="secondary" variant="contained" onClick={handleSubmit}>
                                    Send OTP
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default SendOtp;

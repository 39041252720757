import { post } from "services";
import {
    TOGGLE_LOADING,
    TOGGLE_MODAL,
    LIST_HOMEPAGE_DATA,
    RESET_HOMEPAGE_DATA,
    LIST_USER_PROFILE,
    RESET_USER_PROFILE,
} from "../types";
import { FETCH_HOMEPAGE_DATA, FETCH_USER_PROFILE } from "graphql";

export const listUserProfile = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_MODAL, payload: false });

        let apiResponse = await post("", {
            query: FETCH_USER_PROFILE,
        });

        if (apiResponse.data.customStatus) {
            let {
                view_profile: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_USER_PROFILE,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listHomepageData = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_MODAL, payload: false });

        let apiResponse = await post("", {
            query: FETCH_HOMEPAGE_DATA,
        });

        if (apiResponse.data.customStatus) {
            let {
                homepage_reports: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_HOMEPAGE_DATA,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetHomepageData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_HOMEPAGE_DATA });
    };
};

export const resetUserProfileData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_USER_PROFILE });
    };
};

import Login from "views/auth";
import BulkNftUploadForm from "views/bulk-nft-upload";
import Dashboard from "views/dashboard";
import NftDetails from "views/nft-details";
import NftList from "views/nft-list";
import SellNfts from "views/nft-list/sell-nfts";
import UserPage from "views/user-profile";

var adminRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fas fa-home",
        component: Dashboard,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/nfts",
        name: "Art Works",
        icon: "fas fa-images",
        component: NftList,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/profile",
        name: "Profile",
        icon: "fas fa-user",
        component: UserPage,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/nft/:nftId",
        name: "NFT Details",
        icon: "fas fa-user",
        component: NftDetails,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/upload",
        name: "NFT Upload",
        icon: "fas fa-user",
        component: BulkNftUploadForm,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/sell-nfts",
        name: "Sell NFT",
        icon: "fas fa-user",
        component: SellNfts,
        layout: "/app",
        visibility: false,
    },
];

var authRoutes = [
    {
        path: "/signin",
        name: "Log In",
        icon: "nc-icon nc-bank",
        component: Login,
        layout: "/auth",
    },
];

export { adminRoutes, authRoutes };

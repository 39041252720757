export const FETCH_USER_PROFILE = `query{
    view_profile{
      message
      data {
        _id
        name
        email
        img
        is_creator
        acc_balance
      }
    }
  }`;

export const FETCH_HOMEPAGE_DATA = `query{
    homepage_reports{
      message
      data {
        total_own_nfts
        total_sold_nfts
      }
    }
  }`;

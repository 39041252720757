import React from "react";
import { Modal, ModalBody, Spinner, Row, Col } from "reactstrap";

const Loader = ({ visible }) => {
    return (
        <Modal centered isOpen={visible} backdrop contentClassName="bg-dark">
            <ModalBody style={{ display: "flex", justifyContent: "center", backgroundColor: "black" }}>
                <Row>
                    <Col>
                        <Spinner color="primary" />
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default Loader;

import React from "react";
import { useDispatch } from "react-redux";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { toggleModal } from "store/actions";

const CustomModal = ({ title = "", content, visible }) => {
    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(toggleModal(false));
    };

    return (
        <Modal centered isOpen={visible} backdrop toggle={toggle}>
            <ModalHeader style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ fontSize: 20, textTransform: "uppercase" }}>{title}</div>
            </ModalHeader>
            <ModalBody>{content}</ModalBody>
        </Modal>
    );
};

export default CustomModal;

export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LIST_ALL_NFTS = "LIST_ALL_NFTS";
export const RESET_NFT_LIST_DATA = "RESET_NFT_LIST_DATA";
export const LIST_NFT_DETAILS = "LIST_NFT_DETAILS";
export const RESET_NFT_DETAILS_DATA = "RESET_NFT_DETAILS_DATA";

export const LIST_USER_PROFILE = "LIST_USER_PROFILE";
export const RESET_USER_PROFILE = "RESET_USER_PROFILE";

export const LIST_HOMEPAGE_DATA = "LIST_HOMEPAGE_DATA";
export const RESET_HOMEPAGE_DATA = "RESET_HOMEPAGE_DATA";

export const SET_SELLING_ITEMS = "SET_SELLING_ITEMS";
export const RESET_SELLING_ITEMS = "RESET_SELLING_ITEMS";

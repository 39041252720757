/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card } from "reactstrap";
import { Grid } from "@mui/material";

import CustomTable from "components/Custom/Tables/CustomTable";
import CustomIconButton from "components/Custom/Buttons/IconButton";
import CustomModal from "components/Custom/Modal";
import CreateNewNft from "./CreateNewNft";

import { listAllNfts, resetNftsData, toggleModal } from "store/actions";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import { history } from "utils";

const NftList = () => {
    const dispatch = useDispatch();

    const { nftsData, showViewMore, showModal } = useSelector(({ nfts: { list, showViewMore }, modal }) => ({
        nftsData: list,
        showViewMore,
        showModal: modal,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        fetchMoreNfts();
    }, []);

    const fetchMoreNfts = () => {
        dispatch(listAllNfts());
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <CustomIconButton
                                            id="add-new-btn"
                                            title="Add New"
                                            icon="fas fa-plus"
                                            onClick={() => dispatch(toggleModal(true))}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomIconButton
                                            id="upload-btn"
                                            title="Bulk Upload"
                                            icon="fas fa-upload"
                                            onClick={() => history.push("/app/upload")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomTextButton
                                            title="Sell NFTs"
                                            onClick={() => history.push("/app/sell-nfts")}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <CustomTable
                            title="Art Works"
                            columns={[
                                {
                                    title: "Actions",
                                    render: (rowData) => (
                                        <CustomTextButton
                                            title="open"
                                            otherProps={{ size: "sm" }}
                                            onClick={() => history.push(`/app/nft/${rowData._id}`)}
                                        />
                                    ),
                                },
                                { title: "Name", field: "name" },
                                { title: "Description", field: "description" },
                                { title: "NFT ID", field: "nft_id" },
                                {
                                    title: "Artwork",
                                    field: "art_url",
                                    render: (rowData) => <img src={rowData?.art_url} width={40} height={40} />,
                                },
                                { title: "Status", field: "status" },
                            ]}
                            data={nftsData}
                            showViewMoreButton={showViewMore}
                            viewMoreButtonClick={() => fetchMoreNfts()}
                        />
                    </Grid>
                </Grid>
            </Container>
            <CustomModal content={<CreateNewNft />} visible={showModal} title="artwork details" />
        </>
    );
};

export default NftList;

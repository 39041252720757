import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Card, CardBody, CardHeader, Input, Button } from "reactstrap";

const LoginWithOtp = ({ onSubmit }) => {
    return (
        <Card>
            <CardHeader>Enter OTP to Sign In</CardHeader>
            <CardBody>
                <Formik
                    initialValues={{
                        otp: "",
                    }}
                    validationSchema={Yup.object().shape({
                        otp: Yup.string().max(255).required("OTP is required"),
                    })}
                    onSubmit={onSubmit}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Input
                                autoComplete="off"
                                type="text"
                                placeholder="OTP"
                                name="otp"
                                defaultValue={values.otp}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required={true}
                            />
                            {Boolean(touched.otp && errors.otp) ? (
                                <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                    {errors.otp}
                                </div>
                            ) : (
                                ""
                            )}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button color="secondary" variant="contained" onClick={handleSubmit}>
                                    Sign In
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default LoginWithOtp;

/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Card, Jumbotron } from "reactstrap";
import { Grid } from "@mui/material";
//styles
import "./img-styles.css";

import CustomBackButton from "components/Custom/Buttons/BackButton";
import { listNftDetails, resetNftDetailsData, toggleModal } from "store/actions";
import CustomModal from "components/Custom/Modal";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import ShareNft from "./shareNft";
import { toast } from "react-toastify";

const NftDetails = () => {
    const dispatch = useDispatch();
    const { nftId } = useParams();

    const [showImg, setShowImg] = useState(true);

    const { nftDetails, showModal } = useSelector(({ nfts: { details }, modal }) => ({
        nftDetails: details,
        showModal: modal,
    }));

    useEffect(() => {
        dispatch(resetNftDetailsData());
        dispatch(listNftDetails({ id: nftId }));
    }, []);

    const modalContent = () => {
        if (showImg) {
            return (
                <div style={{ padding: "1em", display: "flex", justifyContent: "center" }}>
                    <img src={nftDetails?.art_url} />
                </div>
            );
        } else {
            return <ShareNft nftId={nftDetails?._id} />;
        }
    };

    const handleShareButton = () => {
        if (nftDetails?.is_for_sale) {
            return toast.info("Not able to transfer artwork added to sales");
        }

        if (nftDetails?.status === "sold") {
            return toast.info("Not able to transfer sold artwork");
        }

        if (nftDetails?.status === "pending") {
            return toast.info("Not able to transfer pending artwork");
        }

        setShowImg(false);
        dispatch(toggleModal(true));
    };

    return (
        <>
            <Container fluid>
            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Card>
                        <div style={{ padding: "1em" }}>
                            <Grid container>
                                <Grid item>
                                    <CustomBackButton />
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            </Container>
            <Container fluid>
            <Grid container>
                <Grid item xl={3} lg={3} md={3} className="container">
                    <img src={nftDetails?.art_url} className="image" style={{ width: "100%" }} />
                    <div className="middle">
                        <div
                            className="text"
                            onClick={() => {
                                setShowImg(true);
                                dispatch(toggleModal(true));
                            }}
                        >
                            View Original
                        </div>
                    </div>
                </Grid>
                <Grid item  xl={9} lg={9} md={9}>
                    <Jumbotron style={{ height: "100%" }}>
                        <h1 className="display-5">{nftDetails?.name}</h1>
                        <p className="lead">{nftDetails?.description}</p>
                        <hr className="my-2" />
                        <p>NFT ID: {nftDetails?.nft_id}</p>
                        <p>Owner: {nftDetails?.owner_id?.name}</p>
                        <p>Royalties: {nftDetails?.royalty_percentage}%</p>
                        <p>Status: {nftDetails?.status}</p>
                        <p>
                            <CustomTextButton title="Share Artwork" onClick={() => handleShareButton()} />
                        </p>
                        <p className="lead"></p>
                    </Jumbotron>
                </Grid>
            </Grid>
            </Container>
            <CustomModal title={showImg ? "" : "User Details"} visible={showModal} content={modalContent()} />
        </>
    );
};

export default NftDetails;

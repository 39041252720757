import { combineReducers } from "redux";
import { LOGOUT } from "store/types";
import loadingReducer from "./loadingReducer";
import modalReducer from "./modalReducer";
import authReducer from "./authReducer";
import nftReducer from "./nftReducer";
import userReducer from "./userReducer";

const appReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    modal: modalReducer,
    nfts: nftReducer,
    users: userReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;

export const FETCH_ALL_NFTS = `query ($pageSize: Int, $pageNumber: Int, $status: String) {
    list_all_nfts(page_size: $pageSize, page_number: $pageNumber, status: $status) {
      message
      data {
        _id
        name
        description
        art_url
        nft_id
        status
      }
      hasMore
    }
  }
  `;

export const CREATE_NEW_NFT = `mutation ($name: String!, $description: String, $artwork: String!, $royalty: Float) {
  create_new_nft_data(name: $name, description: $description, file: $artwork, royalty_percentage: $royalty) {
    message
    _id
  }
}
`;

export const FETCH_NFT_DETAILS = `query ($id: String!) {
  list_nft_details(_id: $id) {
    message
    data {
      _id
      name
      description
      update_authority
      art_url
      royalty_percentage
      nft_id
      is_for_sale
      selling_price
      status
      creator_id {
        _id
        name
        email
      }
      owner_id {
        _id
        name
        email
      }
    }
  }
}
`;

export const TRANSFER_NFT_TO_EMAIL = `mutation ($email: String!, $nftId: String!, $name: String) {
  send_nft_to_email(email: $email, nft_id: $nftId, name: $name) {
    message
  }
}
`;

export const SEND_FOR_CLOUD_UPLOAD = `mutation ($data: [save_for_cloud_upload_data]) {
  save_for_cloud_upload(data: $data) {
    message
  }
}
`;

export const SELL_NFTS = `mutation ($data: [String]!, $amount: Float!) {
  sell_nfts(nft_data: $data, amount: $amount) {
    message
  }
}
`;
